// Fontawesome
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css');

// Reset
@import '../../node_modules/normalize.css/normalize.css';

// Variables
// https://colors.dopely.top/palette/7qyno4/
$spacer: 2rem;
$container: 960px;
$border-radius: 10px;

$primary: #045C94;
$body: #708292;
$background: #ffffff;
$light: lighten($body, 10%);
$dark: darken($primary, 10%);

$font-size-base: 1.2rem;
$font-size-small: $font-size-base * 0.8;
$font-size-large: $font-size-base * 1.2;

$line-height: 1.8;

*,
*::before,
*::after {
    box-sizing: border-box;
}

// Mains
body{
    margin: 0;
    padding: 0;
    font-family: "Montserrat", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", Oxygen, Cantarell, sans-serif;
    line-height: $line-height;
    font-size: $font-size-base;
}

img{
    max-width: 100%;
    height: auto;
}

.container{
    max-width: $container;
    margin-left: auto;
    margin-right: auto;
    padding-left: $spacer;
    padding-right: $spacer;
}

a{
    color: $primary;
    text-decoration: none;

    &:hover{
        text-decoration: underline;
        color: $dark;
    }
}

h1, h2, h3, h4, h5, h6, p{
    margin-top: 0;
    margin-bottom: $spacer;
}

code{
    background: $light;
    padding: 0 .1rem;
}

.margin-top{
    margin-top: $spacer;
}

header{
    h1{
        //text-align: center;
        color: $dark;
        margin-top: $spacer;
        margin-bottom: 0;
    }

    .claim{
        //text-align: center;
        color: $light;
        font-size: $font-size-large;
        margin-bottom: $spacer;
    }
}

section{
    padding: $spacer 0;
}

#portrait{
    padding: 0;
    margin: $spacer 0;
    text-align: center;

    img{
        width: 100%;
        height: auto;
        max-height: 60vh;
        object-fit: cover;
        object-position: center;
        border-radius: $border-radius;
    }
}

#presentation{

}

#education, #work-experience, #skills{
    .list{
        list-style: none;
        margin: 0;
        padding: 0;

        .list-item{
            margin-bottom: $spacer;

            .list-item-year,
            .list-item-position,
            .list-item-society,
            .list-item-body{
                display: block;
            }

            .list-item-year{
                color: $light;
                font-weight: bold;
                font-size: $font-size-small;
                text-transform: uppercase;
            }

            .list-item-position{
                font-weight: bold;
            }

            .list-item-society{
                // font-size: $font-size-small;
                // margin-left: $spacer;
            }

            .list-item-body{
                font-size: $font-size-small;
            }
        }
    }
}

#contacts{
    ul#contacts-list{
        list-style: none;
        margin: 0;
        padding: 0;

        li.contacts-list-item{
            display: inline-block;

            &:not(:last-child){
                padding-right: $spacer;
            }

            i{
                color: $light
            }
        }
    }
}

footer{
    font-size: $font-size-small;
    color: $light;
    text-align: center;
    padding: $spacer 0;

    a{
        color: inherit;
    }

}
